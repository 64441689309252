<template>
  <div style="">
    <el-row class="p_title" >
      <el-col :span="3" style="margin-right:12px">
        <el-select v-model="queryParams.seat" :disabled="!isAdmin" clearable :placeholder="$t('pleaseSelect')" size="small" >
          <el-option
              v-for="(item, i) in seatList"
              :value="item.user_id"
              :label="item.user_name_en|priorFormat(item.user_name_zh,LOCALE)"
              :key="i"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select v-model="queryParams.type" size="small">
          <el-option
              v-for="(item, i) in typeList"
              :value="item.value"
              :label="item.label"
              :key="i"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <div class="line" style="border-bottom: 10px solid #f2f2f2"></div>
    <div class="tableBlock" style="background:#fff;padding: 16px 12px">
      <el-table
          :data="tableData"
          style="width: 100%;"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 74 }"
          :tableLoading="loading"
      >
        <!-- 洽谈时间 -->
        <el-table-column :label="$t('talkTime')" prop="talkTime" width="240">
          <template slot-scope="scope">
            <span>{{ scope.row.start_time|secondFormat('LLL')}}-{{ scope.row.end_time | secondFormat('T') }}</span>
          </template>
        </el-table-column>
        <!-- 访客 -->
        <el-table-column :label="$t('visitor')" width="240">
          <template slot-scope="scope">
            <div class="visitor">
              <el-image class="visitorHead pointer" :fit="'cover'" :src="scope.row.send_user_avatar?scope.row.send_user_avatar:DEFAULT_AVATAR" @click="getCard(scope.row)"></el-image>
              <div style="width:150px">
                <div class="visitorName pointer textOverflow" @click="getCard(scope.row)" :title="scope.row.send_user_name_en|priorFormat(scope.row.send_user_name_zh,LOCALE)">{{scope.row.send_user_name_en|priorFormat(scope.row.send_user_name_zh,LOCALE)}}</div>
                <div class="visitorJob pointer textOverflow" :title="scope.row.send_user_job_title_en|priorFormat(scope.row.send_user_job_title_zh,LOCALE)">{{scope.row.send_user_job_title_en|priorFormat(scope.row.send_user_job_title_zh,LOCALE)}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 企业名称 -->
        <el-table-column :label="$t('companyName')"  width="230">
          <template slot-scope="scope">
            <div 
            class="textOverflow" 
            @click="goDetail(scope.row)" 
            :class="[scope.row.send_user_company_booth_id?'pointercolor':'']"
            :title="scope.row.send_user_company_name_en | priorFormat(scope.row.send_user_company_name_zh,LOCALE)" >
              {{ scope.row.send_user_company_name_en | priorFormat(scope.row.send_user_company_name_zh,LOCALE) }}
              </div>
          </template>
        </el-table-column>
        <!-- 洽谈类型 -->
        <el-table-column :label="$t('qiatan_type')">
          <template >
            <!-- 即时洽谈':'预约洽谈 -->
            <span>{{ queryParams.type==1?$t('Instant_negotiation'):$t('Make_Appointment') }}</span>
          </template>
        </el-table-column>
        <!-- 坐席 -->
        <el-table-column prop="apply_type" :label="$t('Seat')" width="230">
            <template  slot-scope="scope" >
                <div class="textOverflow" :title="scope.row.in_user_name_en|priorFormat(scope.row.in_user_name_zh,LOCALE)" >{{scope.row.in_user_name_en|priorFormat(scope.row.in_user_name_zh,LOCALE)}}</div>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
          @current-change="handleChange"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
    <cardDialog :followedId="currentId"></cardDialog>
  </div>
</template>

<script>
import cardDialog from "@/baseComponents/card/cardDialog";

export default {
  name: "index",
  components: {cardDialog},
  data() {
    return {
      seatList: [],
      booth_type:"",
      typeList: [
        {
          value: 1,
          label: this.$t('Instant_negotiation')
        },
        {
          value: 2,
          label: this.$t('Make_Appointment')
        },
      ],
      queryParams: {
        seat: null,
        type: 1
      },
      currentPage:1,
      tableData: [],
      total: 0,
      currentId: null,
      tableHeight: 100,
      loading: false,
      isAdmin:false,
    }
  },
  watch:{
    "queryParams.seat":function(value){
        this.currentPage=1
        this.queryParams.type=1
        this.getList()
    },
    "queryParams.type":function(value){
        this.currentPage=1
        this.getList()
    },
  },
  mounted() {
    let query=this._decode(
        this.$route.query.parameter
    )

    console.log(query)
    this.booth_type=query.booth_type
    this.queryParams.seat=query.user_id
    this.isAdmin=query.is_admin
    this.getSelect()
    this.getList()
    console.log(this.$store.state)
  },
  methods: {
    handleChange(e) {
      this.currentPage=e
      this.getList()
    },
    getCard(row) {
      if (row.send_user_id) {
        this.currentId = row.send_user_id;
      } else {
        this.currentId = this.USER_INFO.id;
      }
      this.$nextTick(() => {
        this.$GLOBALEVENT.$emit("OPENUSERCard", {
          followed_user_id:row.send_user_id,
          emit_company_id:row.send_user_company_id,
          show: true,
        });
      })
    },
    async getSelect(){
      let params={
        company_id:this.USER_INFO.company_id,
        meeting_id:this.MEETING_ID,
        booth_type:this.booth_type*1||1 //展位类型：1-标准展位，2-特装展位，3-迷你展位(线下展位没有booth_type 传1 2都行 不能传三)
      }
      let res=await this.$store.dispatch('baseConsole/meeting_booth_user', params);
      this.seatList=res.data
    },
    async getList(){
      this.loading=true
      let params={
        company_id:this.USER_INFO.company_id,//125272
        meeting_id:this.MEETING_ID,
        chat_type:this.queryParams.type,
        source:this.$store.state.baseStore.userInfo.source,
        user_id:this.queryParams.seat,
        start:this.currentPage-1,
        limit:10,
      }
      let res=await this.$store.dispatch('baseConsole/chat_record_list', params);
      this.tableData=res.data
      this.total=res.total
      this.loading=false
    },
    // 进入展台
    goDetail(item) {
      if(!item.send_user_company_booth_id){
        return
      }
        this.$router.push({
            path: "/boothDetail",
            query: {
                company_id: item.send_user_company_id,
                booth_id: item.send_user_company_booth_id,
            },
        });
    },
  }
}
</script>

<style scoped lang="less">
.p_title {
  background: #fff;
  padding: 14px 10px;
}

.line {
  width: 100%;
  height: 1px;
  background: #C0C4CC;
}

.visitor {
  display: flex;

  .visitorHead {
    width: 60px !important;
    height: 60px  !important;
    margin-right: 10px;
    cursor:pointer;
  }

  .visitorName {
    font-weight: bold;
    margin-top: 10px;
    max-width: 250px;
    cursor:pointer;
  }
  .visitorJob {
    margin-top: 10px;
    color: #909399;
    max-width: 250px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    cursor:pointer;
    font-weight: 400;
  }
}

.pointercolor {
  cursor: pointer;
  color: #0083f6 !important;
}
</style>